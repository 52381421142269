import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In the world of `}<a parentName="p" {...{
        "href": "/Naos",
        "title": "Naos"
      }}>{`Naos`}</a>{`, dreaming happens when the soul of a creature leaves its body, hanging along its `}<a parentName="p" {...{
        "href": "/Thread",
        "title": "Thread"
      }}>{`Thread`}</a>{` somewhere between the mortal realm and `}<a parentName="p" {...{
        "href": "/The%20Luminary",
        "title": "The Luminary"
      }}>{`The Luminary`}</a>{`. There those with the ability to walk among this dreamscape, called `}<a parentName="p" {...{
        "href": "/Threadwalkers",
        "title": "Threadwalkers"
      }}>{`Threadwalkers`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      